import React from "react"

import { handleAuthentication } from "../../utils/auth"
import LoadingProvider from "../../components/mdxComponents/loading"

const Callback = () => {
  handleAuthentication()

  return <LoadingProvider />
}

export default Callback
